<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :id="'type-company'" :title="$t('company.company')">
						<v-row dense>
							<v-col cols="2">
								<pui-select
									id="companytypeid-company"
									attach="companytypeid-company"
									:label="$t('company.companytype')"
									toplabel
									clearable
									required
									v-model="model"
									modelName="companytype"
									:modelFormMapping="{ companytypeid: 'companytypeid' }"
									:itemsToSelect="[{ companytypeid: model.companytypeid }]"
									:itemValue="['companytypeid']"
									:itemText="(item) => `${item.description}`"
									:order="{ description: 'asc' }"
								></pui-select>
							</v-col>
							<v-col cols="3">
								<pui-select
									id="portauthorityid-company"
									attach="portauthorityid-company"
									:label="$t('company.portauthorityid')"
									toplabel
									clearable
									v-model="model"
									modelName="portauthority"
									:disabled="formDisabled"
									:modelFormMapping="{ id: 'portauthorityid' }"
									:itemsToSelect="[{ id: model.portauthorityid }]"
									:itemValue="['id']"
									:itemText="(item) => `${item.name}`"
									:order="{ name: 'asc' }"
									:fixedFilter="filterByPortAuthorityUser"
								></pui-select>
							</v-col>
							<v-col cols="3">
								<pui-select
									id="portid-company"
									attach="portid-company"
									:label="$t('company.portid')"
									toplabel
									clearable
									v-model="model.ports"
									modelName="port"
									:disabled="formDisabled"
									:fixedFilter="filterByPortUser"
									filterId="portauthorityid-company"
									:filterMap="{ portauthorityid: model.portauthorityid }"
									:filterParentMap="{ portauthorityid: 'portauthorityid' }"
									:itemsToSelect="model.ports"
									return-object
									itemValue="id"
									itemText="portname"
									multiple
									:order="{ description: 'asc' }"
								></pui-select>
							</v-col>
							<v-col cols="3">
								<pui-text-field
									:id="`name-company`"
									v-model="model.name"
									:label="$t('company.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
							<v-col cols="2">
								<pui-text-field
									:id="`cif-company`"
									v-model="model.cif"
									:label="$t('company.cif')"
									:disabled="formDisabled"
									toplabel
									maxlength="20"
								></pui-text-field>
							</v-col>
							<v-col cols="2">
								<pui-text-field
									:id="`companycode-company`"
									v-model="model.companycode"
									:label="$t('company.companycode')"
									:disabled="formDisabled"
									toplabel
									maxlength="2147483647"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row class="align-center">
							<v-col cols="2">
								<pui-text-field
									:id="`phonenumber-company`"
									v-model="model.phonenumber"
									:label="$t('company.phonenumber')"
									:disabled="formDisabled"
									toplabel
									maxlength="2147483647"
								></pui-text-field>
							</v-col>
							<v-col cols="2">
								<pui-text-field
									:id="`fax-company`"
									v-model="model.fax"
									:label="$t('company.fax')"
									:disabled="formDisabled"
									toplabel
									maxlength="2147483647"
								></pui-text-field>
							</v-col>
							<v-col cols="3">
								<pui-text-field
									:id="`email-company`"
									v-model="model.email"
									:label="$t('company.email')"
									:disabled="formDisabled"
									toplabel
									maxlength="2147483647"
								></pui-text-field>
							</v-col>
							<v-col cols="2">
								<pui-checkbox :label="$t('company.disabled')" v-model="model.disabled" :disabled="formDisabled"></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import companyActions from './CompanyActions';

export default {
	name: 'company-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'company',
			actions: companyActions.formActions
		};
	},
	methods: {},
	computed: {
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'id', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }]
			};
		},
		filterByPortUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'in', data: this.model.portauthorityid }]
			};
		}
	},
	created() {}
};
</script>
